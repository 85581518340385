import { SetupContext } from "@vue/composition-api";

export default function useAuthPermission({ root }: Partial<SetupContext>) {
  const hasAccessTo = (permission: string) =>
    root?.$store.getters["user/hasAccessTo"](permission);

  const adminAccess = hasAccessTo("admin");

  const participantsManagement =
    hasAccessTo("participants.participantsManagement") &&
    hasAccessTo("coordinator");
  const participantsInvoice =
    hasAccessTo("participants.participantsinvoice") &&
    hasAccessTo("coordinator");
  const participantsList =
    hasAccessTo("participants.participantsList") && hasAccessTo("coordinator");

  const companiesList =
    hasAccessTo("companies.companiesList") && hasAccessTo("coordinator");
  const companiesManagement =
    hasAccessTo("companies.companiesManagement") && hasAccessTo("coordinator");

  const eventProgramManagement =
    hasAccessTo("eventProgramModule") && hasAccessTo("coordinator");

  const abstractsLists =
    hasAccessTo("abstracts.abstractsLists") && hasAccessTo("coordinator");
  const abstractsManagement =
    hasAccessTo("abstracts.abstractsManagement") && hasAccessTo("coordinator");

  const accessControlView = hasAccessTo("accessControl.accessControlView");
  const accessControlManagement = hasAccessTo(
    "accessControl.accessControlManagement"
  );

  const posterSessionList =
    hasAccessTo("posterSession.posterSessionList") &&
    hasAccessTo("coordinator");

  const surveysList =
    hasAccessTo("surveys.surveysList") && hasAccessTo("coordinator");

  const printList =
    hasAccessTo("print.printList") && hasAccessTo("coordinator");
  const printManagement =
    hasAccessTo("print.printManagement") && hasAccessTo("coordinator");

  const reportsManagement =
    hasAccessTo("reports.reportsManagement") && hasAccessTo("coordinator");

  const infoAccess = hasAccessTo("info.view") && hasAccessTo("coordinator");

  const eventConfig =
    (hasAccessTo("eventConfig.discounts") ||
      hasAccessTo("eventConfig.eventData") ||
      hasAccessTo("eventConfig.participantGroups")) &&
    hasAccessTo("coordinator");
  const eventConfigData =
    hasAccessTo("eventConfig.eventData") && hasAccessTo("coordinator");
  const eventConfigDiscounts =
    hasAccessTo("eventConfig.discounts") && hasAccessTo("coordinator");
  const eventConfigParticipantGroups =
    hasAccessTo("eventConfig.participantGroups") && hasAccessTo("coordinator");

  const registrationModule =
    (hasAccessTo("eventModules.registrationModule.moduleData") ||
      hasAccessTo("eventModules.registrationModule.modileDegree") ||
      hasAccessTo("eventModules.registrationModule.moduleFee")) &&
    hasAccessTo("coordinator");
  const registrationModuleData =
    hasAccessTo("eventModules.registrationModule.moduleData") &&
    hasAccessTo("coordinator");
  const registrationModuleFee =
    hasAccessTo("eventModules.registrationModule.moduleFee") &&
    hasAccessTo("coordinator");

  const accomodationModule =
    (hasAccessTo("eventModules.accomodationModule.moduleData") ||
      hasAccessTo("eventModules.accomodationModule.moduleHotels")) &&
    hasAccessTo("coordinator");
  const accomodationModuleData =
    hasAccessTo("eventModules.accomodationModule.moduleData") &&
    hasAccessTo("coordinator");
  const accomodationModuleHotels =
    hasAccessTo("eventModules.accomodationModule.moduleHotels") &&
    hasAccessTo("coordinator");

  const additionalServicesModule =
    (hasAccessTo("eventModules.additionalServicesModule.moduleData") ||
      hasAccessTo("eventModules.additionalServicesModule.moduleForms") ||
      hasAccessTo("eventModules.additionalServicesModule.moduleServices")) &&
    hasAccessTo("coordinator");
  const additionalServicesModuleData =
    hasAccessTo("eventModules.additionalServicesModule.moduleData") &&
    hasAccessTo("coordinator");
  const additionalServicesModuleForms =
    hasAccessTo("eventModules.additionalServicesModule.moduleForms") &&
    hasAccessTo("coordinator");
  const additionalServicesModuleServices =
    hasAccessTo("eventModules.additionalServicesModule.moduleServices") &&
    hasAccessTo("coordinator");

  const abstractsModule =
    (hasAccessTo("eventModules.abstractsModule.moduleData") ||
      hasAccessTo("eventModules.abstractsModule.modulePublications") ||
      hasAccessTo("eventModules.abstractsModule.moduleSessions")) &&
    hasAccessTo("coordinator");
  const abstractsModuleData =
    hasAccessTo("eventModules.abstractsModule.moduleData") &&
    hasAccessTo("coordinator");
  const abstratcsModulePublications =
    hasAccessTo("eventModules.abstractsModule.modulePublications") &&
    hasAccessTo("coordinator");
  const abstractsModuleSessions =
    hasAccessTo("eventModules.abstractsModule.moduleSessions") &&
    hasAccessTo("coordinator");

  const partnersModule =
    hasAccessTo("eventModules.partnersModule.view") &&
    hasAccessTo("coordinator");
  const eventProgramModule =
    (hasAccessTo("eventModules.eventProgramModule.moduleLocalization") ||
      hasAccessTo("eventModules.eventProgramModule.modulePartner")) &&
    hasAccessTo("coordinator");
  const posterSessionModule =
    hasAccessTo("eventModules.posterSessionModule.view") &&
    hasAccessTo("coordinator");
  const surveysModule =
    hasAccessTo("eventModules.surveysModule.view") &&
    hasAccessTo("coordinator");
  const companiesModule =
    (hasAccessTo("eventModules.companiesModule.moduleAdditionalServices") ||
      hasAccessTo("eventModules.companiesModule.moduleCodes") ||
      hasAccessTo("eventModules.companiesModule.moduleData") ||
      hasAccessTo("eventModules.companiesModule.moduleFees") ||
      hasAccessTo("eventModules.companiesModule.moduleHotels")) &&
    hasAccessTo("coordinator");
  const receptionModule =
    (hasAccessTo("eventModules.receptionModule.moduleCertificates") ||
      hasAccessTo("eventModules.receptionModule.moduleIdentities") ||
      hasAccessTo("eventModules.receptionModule.moduleKiosks") ||
      hasAccessTo("eventModules.receptionModule.moduleTerminals")) &&
    hasAccessTo("coordinator");
  const autoMessagesModule =
    hasAccessTo("eventModules.autoMessagesModule.view") &&
    hasAccessTo("coordinator");

  const siemensReports =
    hasAccessTo("siemens.reports") && hasAccessTo("coordinator");

  const lecturersList =
    hasAccessTo("lecturers.lecturersList") && hasAccessTo("coordinator");
  const lecturersManagement =
    hasAccessTo("lecturers.lecturersManagement") && hasAccessTo("coordinator");
  return {
    adminAccess,

    participantsList,
    participantsManagement,
    participantsInvoice,

    companiesList,
    companiesManagement,

    eventProgramManagement,

    abstractsLists,
    abstractsManagement,

    accessControlView,
    accessControlManagement,

    posterSessionList,

    surveysList,

    printList,
    printManagement,

    reportsManagement,

    infoAccess,

    eventConfig,
    eventConfigData,
    eventConfigDiscounts,
    eventConfigParticipantGroups,

    registrationModule,
    registrationModuleData,
    registrationModuleFee,

    accomodationModule,
    accomodationModuleData,
    accomodationModuleHotels,

    additionalServicesModule,
    additionalServicesModuleData,
    additionalServicesModuleForms,
    additionalServicesModuleServices,

    abstractsModule,
    abstractsModuleData,
    abstratcsModulePublications,
    abstractsModuleSessions,

    partnersModule,
    eventProgramModule,
    posterSessionModule,
    surveysModule,
    companiesModule,
    receptionModule,
    autoMessagesModule,

    siemensReports,

    lecturersList,
    lecturersManagement,
  };
}
