














































































































































































































import useAuthPermission from "@/use/authPermissions";
import useMisc from "@/use/misc";
import useParticipant from "@/use/participant";
import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MSearchResultsItem: () =>
      import(
        "@/components/molecules/participant/advancedSearch/m-search-results-item.vue"
      ),
    MParticipantOptions: () =>
      import("@/components/molecules/participant/m-participant-options.vue"),
    MParticipantInvoice: () =>
      import("@/components/molecules/participant/m-participant-invoice.vue"),
    MSingleInvoice: () =>
      import("@/components/molecules/participant/m-single-invoice.vue"),
    MSendInvoice: () =>
      import("@/components/molecules/participant/m-send-invoice.vue"),
    MParticipantNfc: () =>
      import("@/components/molecules/participant/m-participant-nfc.vue"),
    MRemoveNfc: () => import("@/components/molecules/m-remove-nfc.vue"),
  },
  props: {
    results: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const {
      getParticipantStatusName,
      getParticipantStatusColor,
      getParticipantInvoiceType,
    } = useParticipant({ root });

    const { getLanguageVersion } = useMisc({ root });
    const { participantsManagement, participantsInvoice } = useAuthPermission({
      root,
    });

    const state = reactive({
      singleSelect: false,
      selected: [],
      expanded: [],
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "lastName", text: root.$tc("layout.misc.lastName") },
        {
          value: "firstName",
          text: root.$tc("layout.misc.firstName"),
          sortable: true,
        },
        {
          value: "state",
          text: root.$tc("layout.misc.state"),
          sortable: false,
        },
        {
          value: "group",
          text: root.$tc("layout.misc.groups"),
          sortable: false,
        },
        { value: "icons", text: "", sortable: false, align: "right", width: 1 },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      empty: false,
      items: props.results.sort((a: any, b: any) =>
        a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
      ),
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: props.results.length,
        sortBy: ["lastName"],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      alerts: [],
      participantList: [] as any,
      invoiceModal: false,
      singleInvoice: {},
      nfcAssignDialog: false,
      nfcAssignItem: {},
      nfcAssignIndex: -1,
      specializations: [],
      letterSort: "",
      singleParticipant: false,
      singleParticipantItem: {},
    });

    const changeStatus = (id: string, status: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        state: status,
      };

      state.loading = true;

      axiosInstance
        .put(`participant/${id}/status`, data)
        .then(() => {
          state.success = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getInvoice = (id: number) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/invoice/${id}`, { params: { type: 1 } })
        .then(({ data }) => {
          state.empty = false;
          state.singleInvoice = data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
          } else {
            console.log(error);
            state.singleInvoice = {};
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const nfcAssignDialog = (item: any) => {
      state.nfcAssignItem = Object.assign({}, item);
      state.nfcAssignDialog = true;
    };

    const singleParticipantDialog = (item: any) => {
      state.singleParticipantItem = Object.assign({}, item);
      state.singleParticipant = true;
    };

    return {
      state,
      getParticipantStatusName,
      getParticipantStatusColor,
      getParticipantInvoiceType,
      getLanguageVersion,
      participantsManagement,
      participantsInvoice,
      changeStatus,
      getInvoice,
      nfcAssignDialog,
      singleParticipantDialog,
    };
  },
});
