import { SetupContext } from "@vue/composition-api"

export default function useParticipant({ root }: Partial<SetupContext>) {
  const getParticipantStatusName = (value: string) => {
    switch (value) {
      case "participant":
        return root?.$tc('participant.status.participant')
      case "reserve":
        return root?.$tc('participant.status.reserve')
      case "resigned":
        return root?.$tc('participant.status.resigned')
      case "removed":
        return root?.$tc('participant.status.removed')
    }
  }

  const getParticipantStatusColor = (value: string) => {
    switch (value) {
      case "participant":
        return "green"
      case "reserve":
        return "orange"
      case "resigned":
        return "deep-orange"
      case "removed":
        return "blue-grey"
    }
  }

  const getParticipantInvoiceType = (value: string) => {
    switch (value) {
      case "none":
        return root?.$tc('participant.invoiceType.none')
      case "individual":
        return root?.$tc('participant.invoiceType.individual')
      case "institutional":
        return root?.$tc('participant.invoiceType.institutional')
    }
  }

  return {
    getParticipantStatusName,
    getParticipantStatusColor,
    getParticipantInvoiceType
  }
}